<div class="p-col-12 dqp-toolbar ig-margin-m-v">
    <div i18n class="ig-advisory">
        To edit the content of the Resources section, remove the default Precisely elements and
        add the content you want to see on the home page.
    </div>
    <span class="ig-grow"></span>
    <a (click)="onCollapseAllClick()" class="expand-collapse-button" i18n>Collapse All</a>
    <span class="expand-collapse-separator">&nbsp;</span>
    <a (click)="onExpandAllClick()" class="expand-collapse-button" i18n>Expand All</a>

</div>
<p-accordion [multiple]="true">
    <p-accordionTab [selected]="false">
        <p-header>
            <span i18n class="p-accordion-header-text">Links</span>
            <ig-field-error-summary [formModel]="linksFormArray"></ig-field-error-summary>
        </p-header>
        <dqp-home-links [settings]="settings"></dqp-home-links>
    </p-accordionTab>

    <p-accordionTab [selected]="false" class="home-tiles-tab">
        <p-header>
            <span i18n class="p-accordion-header-text">Tiles</span>
        </p-header>
        <dqp-home-tiles [settings]="settings"></dqp-home-tiles>
    </p-accordionTab>
</p-accordion>

